import { Analytics, logEvent } from 'firebase/analytics';
import { User } from 'firebase/auth';

/**
 * Analytics service for tracking user interactions using Firebase Analytics
 */
export class AnalyticsService {
  private analytics: Analytics;

  constructor(analytics: Analytics) {
    this.analytics = analytics;
  }

  /**
   * Set user ID for analytics tracking
   * @param user Firebase User object
   */
  setUser(user: User | null): void {
    if (user && !user.isAnonymous) {
      this.trackCustomEvent('login', {
        method: user.providerData[0]?.providerId || 'unknown'
      });
    }
  }

  /**
   * Track page view
   * @param pageName Name of the page being viewed
   * @param pageParams Additional parameters for the page view
   */
  trackPageView(pageName: string, pageParams: Record<string, any> = {}): void {
    this.trackCustomEvent('page_view', {
      page_title: pageName,
      page_location: window.location.href,
      page_path: window.location.pathname,
      ...pageParams
    });
  }

  /**
   * Track prayer method selection
   * @param methodId Prayer method ID
   * @param methodName Prayer method name
   */
  trackPrayerMethodSelected(methodId: string, methodName: string): void {
    this.trackCustomEvent('prayer_method_selected', {
      method_id: methodId,
      method_name: methodName
    });
  }

  /**
   * Track prayer completion
   * @param methodId Prayer method ID
   * @param methodName Prayer method name
   * @param completionType Type of completion (read, listen, etc.)
   * @param duration Duration of prayer session in seconds
   */
  trackPrayerCompletion(
    methodId: string, 
    methodName: string, 
    completionType: string,
    duration: number = 0
  ): void {
    this.trackCustomEvent('prayer_completed', {
      method_id: methodId,
      method_name: methodName,
      completion_type: completionType,
      duration: duration
    });
  }

  /**
   * Track scripture selection
   * @param reference Scripture reference
   * @param isCustom Whether it's a custom scripture or gospel of the day
   */
  trackScriptureSelected(reference: string, isCustom: boolean = false): void {
    this.trackCustomEvent('scripture_selected', {
      reference: reference,
      source: isCustom ? 'custom' : 'gospel_of_day'
    });
  }

  /**
   * Track rosary meditation
   * @param mysteryType Type of mystery (Joyful, Sorrowful, etc.)
   * @param styleId Meditation style ID
   */
  trackRosaryMeditation(mysteryType: string, styleId: string): void {
    this.trackCustomEvent('rosary_meditation', {
      mystery_type: mysteryType,
      style_id: styleId
    });
  }

  /**
   * Track saint meditation
   * @param saintId Saint ID
   * @param saintName Saint name
   * @param meditationType Meditation type (life or meditation)
   */
  trackSaintMeditation(saintId: string, saintName: string, meditationType: string): void {
    this.trackCustomEvent('saint_meditation', {
      saint_id: saintId,
      saint_name: saintName,
      meditation_type: meditationType
    });
  }

  /**
   * Track advisor recommendation
   * @param recommendationId Recommendation ID
   */
  trackAdvisorRecommendation(recommendationId: string): void {
    this.trackCustomEvent('advisor_recommendation', {
      recommendation_id: recommendationId
    });
  }

  /**
   * Track series progress
   * @param seriesId Series ID
   * @param itemId Item ID
   * @param progress Progress value (0-100)
   */
  trackSeriesProgress(seriesId: string, itemId: string, progress: number): void {
    this.trackCustomEvent('series_progress', {
      series_id: seriesId,
      item_id: itemId,
      progress: progress
    });
  }

  /**
   * Track user registration
   * @param method Registration method
   */
  trackUserRegistration(method: string): void {
    this.trackCustomEvent('sign_up', {
      method: method
    });
  }

  /**
   * Track search performed
   * @param term Search term
   * @param category Search category
   */
  trackSearch(term: string, category: string = 'general'): void {
    this.trackCustomEvent('search', {
      search_term: term,
      category: category
    });
  }

  /**
   * Track audio playback events
   * @param audioTitle Audio title
   * @param audioType Audio type
   * @param event Event type (start, pause, resume, complete)
   */
  trackAudioPlayback(audioTitle: string, audioType: string, event: 'start' | 'pause' | 'resume' | 'complete'): void {
    this.trackCustomEvent('audio_playback', {
      title: audioTitle,
      type: audioType,
      event: event
    });
  }

  /**
   * Track feature usage
   * @param featureName Feature name
   * @param actionType Action type
   */
  trackFeatureUsed(featureName: string, actionType: string = 'click'): void {
    this.trackCustomEvent('feature_used', {
      feature_name: featureName,
      action_type: actionType
    });
  }

  /**
   * Track routine created or modified
   * @param routineId Routine ID
   * @param routineName Routine name
   * @param isNew Whether it's a new routine or modification
   */
  trackRoutineAction(routineId: string, routineName: string, isNew: boolean): void {
    this.trackCustomEvent('routine_action', {
      routine_id: routineId,
      routine_name: routineName,
      action: isNew ? 'created' : 'modified'
    });
  }

  /**
   * Track custom event
   * @param eventName Event name
   * @param eventParams Event parameters
   */
  trackCustomEvent(eventName: string, eventParams: Record<string, any> = {}): void {
    try {
      logEvent(this.analytics, eventName, eventParams);
    } catch (error) {
      // Silent fail in production
    }
  }
}

// Singleton instance
let instance: AnalyticsService | null = null;

/**
 * Initialize the analytics service
 * @param analytics Firebase Analytics instance
 * @returns AnalyticsService instance
 */
export const initializeAnalytics = (analytics: Analytics): AnalyticsService => {
  if (!instance) {
    instance = new AnalyticsService(analytics);
  }
  return instance;
};

/**
 * Get the analytics service instance
 * @returns AnalyticsService instance
 */
export const getAnalyticsService = (): AnalyticsService | null => {
  return instance;
}; 