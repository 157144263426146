import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsService, getAnalyticsService } from '../services/analytics/analyticsService';
import { useFirebase } from '@saintly-whispers/shared/src/contexts/FirebaseContext';
import { initializeAnalytics } from '../services/analytics/analyticsService';
import { User } from 'firebase/auth';

interface AnalyticsContextType {
  analyticsService: AnalyticsService | null;
}

const AnalyticsContext = createContext<AnalyticsContextType>({ analyticsService: null });

export const useAnalytics = (): AnalyticsService | null => {
  const { analyticsService } = useContext(AnalyticsContext);
  return analyticsService;
};

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const { analytics } = useFirebase();
  const location = useLocation();
  const analyticsService = analytics ? initializeAnalytics(analytics) : null;

  // Track page views
  useEffect(() => {
    if (analyticsService) {
      const pageName = location.pathname.split('/').filter(Boolean).join('/') || 'home';
      analyticsService.trackPageView(pageName);
    }
  }, [location.pathname, analyticsService]);

  return (
    <AnalyticsContext.Provider value={{ analyticsService }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Hook to track user authentication events
export const useAuthAnalytics = (user: User | null) => {
  const analyticsService = useAnalytics();

  useEffect(() => {
    if (analyticsService && user) {
      analyticsService.setUser(user);
    }
  }, [user, analyticsService]);
}; 