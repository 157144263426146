import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { webFirebaseService } from './firebase';
import { FIRESTORE_PATHS } from '@saintly-whispers/shared/src/services/api/apiConfig';
import { routes } from './routes/routes';
import { ProtectedRoute } from './components/routing/ProtectedRoute';
import { useUserData } from '@saintly-whispers/shared/src/hooks/user/useUserData';
import { useAuthModal } from '@saintly-whispers/shared/src/contexts/AuthModalContext';
import Navbar from './components/nav/Navbar';
import Footer from './components/nav/Footer';
import LoadingSpinner from './components/common/LoadingSpinner';
import AuthModal from './components/authentication/AuthModal';
import { User } from 'firebase/auth';
import { PrayerMethod } from '@saintly-whispers/shared';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom-bootstrap.css';
import './styles/shared.css';
import './styles/components.css';
import { useFirebase } from '@saintly-whispers/shared/src/contexts/FirebaseContext';
import { FirebaseProvider } from '@saintly-whispers/shared/src/contexts/FirebaseContext';
import { UserProvider } from '@saintly-whispers/shared/src/contexts/UserContext';
import { WebFirebaseService } from './services/firebase/WebFirebaseService';
import { initializeApi } from '@saintly-whispers/shared/src/services/api';
import { ApiContext, ApiProvider } from '@saintly-whispers/shared/src/contexts/ApiContext';
import { UseUserDataHook } from '@saintly-whispers/shared/src/types/auth';
import { AnalyticsProvider, useAuthAnalytics } from './contexts/AnalyticsContext';
import { initializeAnalyticsBridge } from './utils/analyticsBridge';

interface AppState {
  user: User | null;
  loading: boolean;
  prayerMethods: PrayerMethod[];
  error: string | null;
}

const AppContent: React.FC = () => {
  const { auth, firestore } = useFirebase();
  const [state, setState] = useState<AppState>({
    user: null,
    loading: true,
    prayerMethods: [],
    error: null
  });

  const { userData, loading: userLoading, error: userError } = useUserData() as UseUserDataHook;
  const { isAuthModalVisible, hideAuthModal, returnUrl } = useAuthModal();
  
  // Initialize analytics tracking for user
  useAuthAnalytics(state.user);

  // Initialize analytics bridge for shared components
  useEffect(() => {
    initializeAnalyticsBridge();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.isAnonymous) {
          console.log('User is signed in anonymously');
        } else {
          console.log('User is signed in with an account:', user.email);
        }
        setState(prev => ({ ...prev, user }));
      } else {
        try {
          const anonymousUser = await webFirebaseService.signInAnonymouslyIfNeeded();
          const userDocRef = doc(firestore, FIRESTORE_PATHS.USERS.path(anonymousUser.uid));
          await setDoc(userDocRef, {
            isAnonymous: true,
            createdAt: serverTimestamp(),
            lastLogin: serverTimestamp(),
          }, { merge: true });
          setState(prev => ({ ...prev, user: anonymousUser }));
        } catch (error) {
          console.error('Error signing in anonymously:', error);
          setState(prev => ({ ...prev, error: 'Failed to sign in anonymously. Please try again later.' }));
        }
      }
      setState(prev => ({ ...prev, loading: false }));
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPrayerMethods = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'prayerMethods'));
        const methods = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as PrayerMethod[];
        setState(prev => ({ ...prev, prayerMethods: methods }));
      } catch (error) {
        console.error("Error fetching prayer methods:", error);
        setState(prev => ({ ...prev, error: "Failed to load prayer methods. Please try again later." }));
      }
    };

    if (state.user) {
      fetchPrayerMethods();
    }
  }, [state.user]);

  if (state.loading || userLoading) {
    return <LoadingSpinner />;
  }

  if (state.error || userError) {
    return <div className="error-message">{state.error || userError}</div>;
  }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <AnalyticsProvider>
          <div className="App">
            <Navbar user={state.user} userData={userData} />
            <main className="main-content">
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {routes.map(({ path, component: Component, protected: isProtected, adminOnly }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        isProtected ? (
                          <ProtectedRoute isAdminRoute={adminOnly}>
                            <Component prayerMethods={state.prayerMethods} />
                          </ProtectedRoute>
                        ) : (
                          <Component prayerMethods={state.prayerMethods} />
                        )
                      }
                    />
                  ))}
                </Routes>
              </Suspense>
            </main>
            <AuthModal 
              show={isAuthModalVisible}
              onHide={hideAuthModal}
              returnUrl={returnUrl}
            />
            <Footer />
          </div>
        </AnalyticsProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

const App: React.FC = () => {
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
  
  const firebaseConfig = isProduction
    ? {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
        appId: process.env.REACT_APP_FIREBASE_APP_ID!
      }
    : {
        apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY!,
        authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN!,
        projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID!,
        storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET!,
        messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID!,
        appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID!,
        measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
      };

  const firebaseService = new WebFirebaseService(firebaseConfig);
  const api = initializeApi(firebaseService);

  return (
    <FirebaseProvider value={firebaseService}>
      <ApiProvider value={api}>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </ApiProvider>
    </FirebaseProvider>
  );
};

export default App;