import { getAnalyticsService } from '../services/analytics/analyticsService';

// Make analytics events available globally
declare global {
  interface Window {
    logAnalyticsEvent?: (eventName: string, eventParams?: Record<string, any>) => void;
  }
}

/**
 * Initialize the analytics bridge to connect shared code
 * with the web app analytics implementation
 */
export const initializeAnalyticsBridge = (): void => {
  // Set up the global analytics event logger
  window.logAnalyticsEvent = (eventName: string, eventParams: Record<string, any> = {}) => {
    const analyticsService = getAnalyticsService();
    if (analyticsService) {
      analyticsService.trackCustomEvent(eventName, eventParams);
    }
  };
}; 